import React from 'react';
import { Pagination } from 'react-bootstrap';

export default function ThumbnailPagination({ currentPage, totalPages, gotoPageHandler }) {

  let items = [];

  if (totalPages <= 10) {

    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={ ()=>gotoPageHandler(number) }>
          {number}
        </Pagination.Item>,
      );
    }

  }
  else {

    items.push(
      <Pagination.Item key={1} active={currentPage === 1} onClick={ ()=>gotoPageHandler(1) }>
        {1}
      </Pagination.Item>
    );

    let start = currentPage - 2;
    let end = currentPage + 2;

    if (currentPage <= 5) {
      start = 2;
      end = 5;
    }

    if (currentPage > 5) {
      items.push(<Pagination.Ellipsis />);
    }

    if (currentPage >= totalPages -4 ) {
      start = totalPages-4;
      end = totalPages-1;
    }

    for (let number = start; number <= end; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={ ()=>gotoPageHandler(number) }>
          {number}
        </Pagination.Item>,
      );
    }

    if (currentPage < totalPages - 4) {
      items.push(<Pagination.Ellipsis />);      
    }

    items.push(
      <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={ ()=>gotoPageHandler(totalPages) }>
        {totalPages}
      </Pagination.Item>
    );

  }

    return (
      <Pagination className="my-3 justify-content-center">
        <Pagination.First key="first" onClick={ () => gotoPageHandler(1) }/>
        <Pagination.Prev key="previous" disabled={currentPage===1} onClick={ () => gotoPageHandler(currentPage-1) }/>

        {items}

        <Pagination.Next key="next" disabled={currentPage===totalPages} onClick={ () => gotoPageHandler(currentPage+1) }/>
        <Pagination.Last key="last" onClick={ () => gotoPageHandler(totalPages) } />
      </Pagination>    
  )
}