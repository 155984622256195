import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ErrorModal(props) {
    const { show, handleClose, errorMessage } = props;

    return(
        <Modal className='error-message' show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Server Error</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            The server returned the following message:
            {errorMessage}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleClose}>Ok</Button>
        </Modal.Footer>
      </Modal>

    )
}