import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './features/home/Home';
import Header from './features/header/Header';
import InfoModal from './features/modals/InfoModal';
import ErrorModal from './features/modals/ErrorModal';
import ThumbnailViewer from './features/thumbnailViewer/ThumbnailViewer';

function App() {
  return (
    <div className="App">

      <InfoModal show={false} />
      <ErrorModal show={false} />

      <div class="loading-overlay">
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>

       <Header />

        <Router>
          <Switch>

            <Route path='/' exact>
              <Home />
            </Route>

            <Route path="/view/:filterMode/:filters+">
              <ThumbnailViewer />
            </Route>

          </Switch>

        </Router>


    </div>
  );
}

export default App;
