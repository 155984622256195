import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const loadAllTags = createAsyncThunk(
    'filters/loadAllTags',
    async (args) => {
        const response = await fetch('https://famphotos.falkor.gen.nz/photo_api/slim/tags');
        const json = await response.json();
        return json;
    }
);


export const loadFilteredTags = createAsyncThunk(
    'filters/loadFilteredTags',
    async (args) => {
        // load filters depending on whether filtermode is all or any

        const filterMode = args.newFilterMode;
        const filters = args.filters;

        let url = '';

        const params = filters.map( tag => `tags[]=${encodeURIComponent(`${tag.group}:${tag.keyword}`)}`).join('&');

        if (filterMode === 'any') {
            url = `https://famphotos.falkor.gen.nz/photo_api/slim/tags/filterAny?${params}`;
        }
        else if (filterMode === 'all') {
            url = `https://famphotos.falkor.gen.nz/photo_api/slim/tags/filterAll?${params}`;
        }

        const response = await fetch(url);
        const json = await response.json();
        return json;
    }
);

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        allTags: [],
        filteredTags: [],
        status: 'idle',
        activeFilters: [],
        filterMode: 'any',
        newFilterMode: 'any',
        photoCount: 0,
        searchString: '',
    },
    reducers: {
        setSearchString: (state, action) => {
            state.searchString = action.payload;
        },
        resetTags: (state) => {
            state.filteredTags = state.allTags;
            state.photoCount = 0;
        },
        addActiveFilter: (state, action) => {
            state.activeFilters.push( {...action.payload, active:true } );
        },
        removeActiveFilter: (state, action) => {
            const { group, keyword } = action.payload;
            state.activeFilters = state.activeFilters.filter( eachTag => !(eachTag.group === group && eachTag.keyword.toLowerCase() === keyword.toLowerCase()) );
        },
        toggleActiveFilter: (state, action) => {
            const { group, keyword, active } = action.payload;

            state.filteredTags[group].tags.forEach( (eachTag) => {
                if (eachTag.keyword.toLowerCase() ===keyword.toLowerCase()) {
                    eachTag.active = !active;
                }
            });

        },

        setFilterMode: (state, action) => {
            state.newFilterMode = action.payload;
        },

        clearActiveFilters: (state) => {
            state.activeFilters = [];
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAllTags.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(loadAllTags.fulfilled, (state, action) => {
                state.status = 'ok';
                
                const loadedTags = action.payload;

                state.allTags = loadedTags;

                // Add a active: false property to each of the filters.tags.tags objects and set currentCount to count
                Object.keys(loadedTags).forEach( group => {
                    loadedTags[group].tags.forEach( tag => {
                        tag.active = false;
                        tag.initialCount = tag.count;
                        // tag.matchingSearch = false;
                    })
                })

                state.filteredTags = loadedTags;

                state.activeFilters=[];

            })
            .addCase(loadAllTags.rejected, (state) => {
                state.status = 'failed'
            })
            .addCase(loadFilteredTags.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(loadFilteredTags.fulfilled, (state, action) => {

                state.photoCount = action.payload.count;
                const newTags = action.payload.groups;

                // Set count to 0 for all tags in groups not returned

                Object.keys(state.filteredTags).forEach( group => {
                    if (!Object.keys(newTags).includes(group)) {
                        state.filteredTags[group].tags.forEach( tag => tag.count = 0 );
                    }
                });

                // Set counts on tags from returned data

                Object.keys(newTags).forEach( group => {
                    state.filteredTags[group].tags.forEach( (tag ) => {
                        const foundTag = newTags[group].tags.find( newTag => newTag.keyword.toLowerCase() === tag.keyword.toLowerCase());
                        if (foundTag) {
                            tag.count = foundTag.count;
                        }
                        else {
                            tag.count=0;
                        }
                    });
                })

                Object.keys(state.filteredTags).forEach( group => {
                    state.filteredTags[group].tags.forEach( tag => {
                        tag.active = state.activeFilters.some( activeTag => activeTag.keyword.toLowerCase()===tag.keyword.toLowerCase() && group === activeTag.group);
                    })
                });
    
                state.filterMode = state.newFilterMode;
                state.status = 'ok';

            })
            .addCase(loadFilteredTags.rejected, (state) => {
                state.status = 'failed'
            })    
        }
});

export const selectAllTags = (state) => state.filters.allTags;
export const selectFilteredTags = (state) => state.filters.filteredTags;
export const selectGroup = (state, action) => state.filters.allTags[action.payload];
export const selectActiveFilters = (state) => state.filters.activeFilters;
export const selectfilterMode = (state) => state.filters.filterMode;

export const { 
    setSearchString, 
    addActiveFilter, 
    removeActiveFilter, 
    toggleActiveFilter, 
    setFilterMode, 
    resetTags,
    clearActiveFilters 
} = filtersSlice.actions;

export default filtersSlice.reducer;