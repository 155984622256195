import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllTags, resetTags, loadFilteredTags, selectActiveFilters, selectFilteredTags, selectfilterMode, addActiveFilter, removeActiveFilter } from './filtersSlice';
import FilterAccordion from './FilterAccordion';
import FilterHeader from './FilterHeader';
import { useHistory } from 'react-router-dom';

export default function Filters() {
    const history = useHistory();
    const dispatch = useDispatch();

    const filteredTags = useSelector(selectFilteredTags);
    const activeFilters = useSelector(selectActiveFilters);
    const status = useSelector((state) => state.filters.status);
    const filterMode = useSelector(selectfilterMode);
    const newFilterMode = useSelector((state) => state.filters.newFilterMode);
    const searchString = useSelector((state) => state.filters.searchString);


    useEffect(() => {
        if (status === 'idle') {
            dispatch(loadAllTags());
        }
    }, [dispatch, status]);

    useEffect(() => {
        if (activeFilters.length === 0) {
            dispatch(resetTags());
        }
        else {
            dispatch(loadFilteredTags({newFilterMode, filters: activeFilters}));
        }
    }, [dispatch, activeFilters, newFilterMode]);

    function tagClickHandler(tag) {
        if (tag.active) {
            dispatch(removeActiveFilter( tag ) );       
        }
        else {
            dispatch(addActiveFilter( tag ) );       
        }
    }

    function loadPhotosHandler() {
        const filtersPath = activeFilters.map( tag => {
            const urlGroup = tag.group.replaceAll(' ', '+');
            const urlKeyword = tag.keyword.replaceAll(' ', '+');
            return `${urlGroup}:${urlKeyword}`;
        }).join('/');
        const location = {
            pathname: `/view/${filterMode}/${filtersPath}`
        }
        console.log(location);
        history.push(`/view/${filterMode}/${filtersPath}`);

    }

    return(
        <Container className="border border-top-0 p-3">
            <div style={{cursor: status==='loading' ? 'wait' : null}}>

            <FilterHeader 
                tagClickHandler={tagClickHandler}
                loadPhotosHandler={loadPhotosHandler}
            />

            <FilterAccordion 
                filteredTags={filteredTags}
                searchString={searchString}
                tagClickHandler={tagClickHandler}
                filterMode={filterMode}
                status={status}
                />

            </div>
        </Container>
    )
}