import React from 'react';
import { Card, Badge } from 'react-bootstrap';
import FilterToggle from './filterToggle';
import FilterButton from './FilterButton';
import { useDispatch, useSelector } from 'react-redux';
import { clearActiveFilters, selectActiveFilters, selectfilterMode, setFilterMode } from './filtersSlice';
import { Button } from 'react-bootstrap';

export default function FilterHeader({ tagClickHandler, loadPhotosHandler }) {

    const dispatch = useDispatch();

    const activeFilters = useSelector(selectActiveFilters);
    const newFilterMode = useSelector((state) => state.filters.newFilterMode);
    const photoCount = useSelector((state) => state.filters.photoCount);
    const status = useSelector((state) => state.filters.status);
    const filterMode = useSelector(selectfilterMode);

    return (
        <Card className="mb-3">
        <Card.Header>Select Photos With Tags
            <Button variant="danger" size="sm" className="float-end" 
                onClick={(e) => dispatch(clearActiveFilters())}>
                    Clear Filters</Button>
        </Card.Header>

        <Card.Body>

            <FilterToggle 
                changeHandler={(e) => dispatch(setFilterMode(e.currentTarget.value))}
                filterMode={newFilterMode}
                loading={status==='loading'}
                /> of:

            {activeFilters.length>0 ? activeFilters.map( (tag) => (
                <FilterButton
                    key={`${tag.group}_${tag.keyword}`}
                    tag={tag} 
                    clickHandler={ tagClickHandler } 
                    grouped={false}
                    visible={true}
                    filterMode={filterMode}
                    loading={status==='loading'}
                    />
            )) : (<span className="text-muted fst-italic p-3">select tags from below</span>)}

        </Card.Body>

        <Card.Footer>

        <Button 
            variant="success" 
            disabled={photoCount===0}
            onClick={loadPhotosHandler}
        >
            View <Badge bg="light" text="dark">{photoCount}</Badge> Selected Photos
        </Button>

        </Card.Footer>
    </Card>        
    )
}