import React from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

export default function FilterToggle(props) {
    const { filterMode, changeHandler, loading } = props;



    return (
        <ButtonGroup >
            <ToggleButton type="radio" id="radio-any" variant="outline-secondary" value='any' checked={filterMode==='any'}
                onChange={changeHandler}>
                <span style={{cursor: loading ? 'wait': null}}>Any</span>
            </ToggleButton>
            <ToggleButton type="radio" id="radio-all" variant="outline-secondary" value='all' checked={filterMode==='all'}
                onChange={changeHandler}>
                <span style={{cursor: loading ? 'wait': null}}>All</span>
            </ToggleButton>
        </ButtonGroup>
    )
}