import React from 'react';
import { useContext, useEffect } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

export default function FilterGroupAccordionToggle({ children, eventKey, disabled, lastGroupOpen }) {
    const { activeEventKey } = useContext(AccordionContext);

    const toggleMe = useAccordionButton(eventKey);

    const isCurrentEventKey = activeEventKey === eventKey;

    useEffect(() => {
        if (disabled && isCurrentEventKey) {
            // Keep a track of the last one open
            lastGroupOpen.current = eventKey;

            // It's currently open so close it
            toggleMe();
        }
        else if (!isCurrentEventKey && !disabled && lastGroupOpen.current === eventKey) {
            // Open it back up if it was the last one open and it's not longer disabled
            toggleMe();
            lastGroupOpen.current = '';
        }
    }, [disabled, isCurrentEventKey, toggleMe, eventKey, lastGroupOpen]);

    function clickHandler() {

        toggleMe();
        lastGroupOpen.current = '';
    }

    const collapsed = isCurrentEventKey ? '' : 'collapsed';
  
    return (
      <button
        disabled={disabled}
        type="button"
        className={`accordion-button ${collapsed} btn btn-light`}
        onClick={clickHandler}
      >
        {children}
      </button>
    );
}
  