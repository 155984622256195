import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import DefaultCollectionsTab from './DefaultCollectionsTab.js';
import ImportTab from './ImportTab.js';
import SyncTab from './SyncTab.js';

export default function AdminTab() {
    return(
        <Container className="border border-top-0 p-3">

            <Tabs defaultActiveKey="import">

                <Tab eventKey="import" title="Import">
                    <ImportTab />
                </Tab>

                <Tab eventKey="default" title="Default Collections">                    
                    <DefaultCollectionsTab />
                </Tab>

                <Tab eventKey="sync" title="Sync">
                    <SyncTab />
                </Tab>

            </Tabs>

        </Container>
    )
}