import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const loadPhoto = createAsyncThunk(
    'viewer/loadPhoto',
    async ({ photoId }) => {

        const url = `https://famphotos.falkor.gen.nz/photo_api/slim/photos/fetch/photo/${photoId}`;

        const response = await fetch(url);
        const json = await response.json();
        return json;
    }
)

export const viewerSlice = createSlice({
    name: 'viewer',
    initialState: {
        loadedPhotoId: null,
        showing: false,
        metaData: {},
        status: 'initial'
    },
    reducers: {
        openPhoto: (state, action) => {
            state.showing = true;
            state.loadedPhotoId = action.payload;
        },
        closePhoto: (state) => {
            state.showing = false;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(loadPhoto.pending, (state) => {
                state.status = 'loading'

            })
            .addCase(loadPhoto.fulfilled, (state, action) => {
                state.status = 'ok';
                
                state.metaData = action.payload.data;

            })
            .addCase(loadPhoto.rejected, (state) => {
                state.status = 'failed'
            })
    }
})

export const {openPhoto, closePhoto} = viewerSlice.actions;

export default viewerSlice.reducer;