import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const loadIdsFromFilters = createAsyncThunk(
    'thumbnails/loadIdsFromFilters',
    async ({ filterMode, filters, page }) => {

        const params = filters.map( tag => `tags[]=${encodeURIComponent(`${tag.group}:${tag.keyword}`)}`).join('&');

        const url = `https://famphotos.falkor.gen.nz/photo_api/slim/photos/fetch/ids/filter/${filterMode}?${params}&page=${page}`;

        const response = await fetch(url);
        const json = await response.json();
        return json;
    }
)

export const thumbnailsSlice = createSlice({
    name: 'thumbnails',
    initialState: {
        photoIds: [],
        thumbnails: [],
        status: 'initial',
        currentPage: 1,
        totalPages: 0,
        filters: []
    },
    reducers: {
        clearPhotoIds: (state) => {
            state.photoIds = [];
        },

        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },

        setFilters: (state, action) => {
            state.filters = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(loadIdsFromFilters.pending, (state) => {
                state.status = 'loading'

            })
            .addCase(loadIdsFromFilters.fulfilled, (state, action) => {
                state.status = 'ok';
                
                state.photoIds = action.payload.data;
                state.totalPages = action.payload.totalPages;

            })
            .addCase(loadIdsFromFilters.rejected, (state) => {
                state.status = 'failed'
            })
    }
})

export const {clearPhotoIds, setCurrentPage, setFilters} = thumbnailsSlice.actions;

export default thumbnailsSlice.reducer;