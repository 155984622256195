import React, { useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import FilterSearch from './filterSearch';
import FilterGroupAccordionToggle from './filterGroupAccordionToggle';
import { Card } from 'react-bootstrap';
import FilterButton from './FilterButton';


export default function FilterAccordion({ filteredTags, searchString, tagClickHandler, filterMode, status }) {

    const lastGroupOpen = useRef('');

    // True if at least one of the tags has positive count and at least one of the tags matches the search string
    function groupHasTags(tagList) {
        // I've decided to view the tags with 0 count
        // return tagList.some( tag => tag.count>0) && tagList.some(tag => tagMatchesSearch(tag));

        return tagList.some(tag => tagMatchesSearch(tag));
    }

    // True if at least one of the words in the tag starts with the search string.
    // Example: "Minnie Torkington" will match the search string "tor"
    function tagMatchesSearch(tag) {
        return tag.keyword.split(' ').some( word => word.toLowerCase().startsWith(searchString.toLowerCase()) );
    }


    return (
        <Accordion defaultActiveKey={Object.keys(filteredTags)[0]}>
        <FilterSearch />
        {Object.entries(filteredTags).map( ( [group, filterGroup] ) =>  (


            <Card className="border-0" key={group}>
                <Card.Header as="h6" className="p-0 border-top-0">
                    <FilterGroupAccordionToggle 
                        disabled={!groupHasTags(filterGroup.tags)}
                        eventKey={group} 
                        key={group}
                        lastGroupOpen={lastGroupOpen}
                        >
                            {group}
                    </FilterGroupAccordionToggle>
                </Card.Header>
                
                <Accordion.Collapse eventKey={`${group}`} key={`${group}`}>

                    <Card.Body>
                        {filterGroup.tags.map( tag => (
                            <FilterButton 
                                key={`${tag.group}_${tag.keyword}`}
                                tag={tag} 
                                clickHandler={ tagClickHandler } 
                                grouped={true} 
                                filterMode={filterMode}
                                visible={
                                    tagMatchesSearch(tag)                                    
                                } 
                                loading={status==='loading'}
                                /> 
                        ))}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>


        ))}                        

    </Accordion>        
    )
}