import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function InfoModal(props) {

    const { infoTitle, infoMessage, show, handleClose} = props;

    return(
        <Modal className='info-message' show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{infoTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {infoMessage}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleClose}>Ok</Button>
        </Modal.Footer>
      </Modal>
      
    )
}