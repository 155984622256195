import React from 'react';
import AdminTab from './AdminTab';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Container, Row, Col } from 'react-bootstrap';
import Filters from '../filters/Filters';


export default function Home() {
    return (
        <Container className="mt-3">
        <Row>
            <Col>

            <Tabs defaultActiveKey="photos" id="home-tabs">

                <Tab eventKey="photos" title="Open Photos">



                    <Filters />

                </Tab>

                <Tab eventKey="admin" title="Administration">

                    <AdminTab />

                </Tab>

            </Tabs>
            </Col>
                 </Row>
             </Container>

    )
}

