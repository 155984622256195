import React, { useEffect } from 'react';
import { openPhoto } from '../viewer/viewerSlice';
import { useDispatch } from 'react-redux';

export default function Thumbnail( {photoInfo } ) {

    const dispatch = useDispatch();

    const photoId = photoInfo.photoId;
    const photoUrl = `https://famphotos.falkor.gen.nz/photo_api/slim/photos/thumbnail/image/${photoId}`;

    const photoCaption = photoInfo.title ? photoInfo.title : photoInfo.file;

    function clickHandler() {
        dispatch(openPhoto(photoInfo.photoId));
    }

    return (
        <button className="p-2 my-3 mx-auto shadow d-inline-block border-0 bg-white" style={{width:null}} onClick={clickHandler} >
            <img className="d-block mx-auto" src={photoUrl} alt="Thumbnail"/>
            <div className="text-muted text-center mt-3 fs-7">{photoCaption}</div>
        </button>
    )
}