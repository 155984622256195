import React from 'react';
import Container from 'react-bootstrap/Container';

export default function DefaultCollectionsTab() {
    return (
        <Container className="border border-top-0 p-3">

            <form className="w3-container w3-padding">
            <p>This sets the default collection for new images found in a directory when synced.</p>

            <div className="w3-row w3-padding" >
                <div className="w3-col m2 l1">
                <b>Directory</b>
                </div>
                <div className="w3-col m3 l4">
                <b>Default Collection</b>
                </div>
            </div>

            <div className="w3-row w3-padding" >
                <div className="w3-col m2 l1">
                    <span data-bind="text: DirName"></span>
                </div>
                <div className="w3-col m3 l4">
                    <input type="text" className="w3-input w3-border" data-bind="value:collection" required />            
                </div>
            </div>

            <div className="w3-button w3-large w3-gray w3-margin-bottom" data-bind="click: saveDefaults">Save</div>

            </form>
        </Container>
    )
}
