import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';


export default function Header() {
    return(
        <Navbar bg="success" className="bg-gradient" expand="lg" variant="dark">
            <Container>

                <Navbar.Brand href="/">
                    <h1>Family Photo Viewer Updated 27 Sep 2021 3:30pm</h1>
                </Navbar.Brand>
                    
                <Navbar.Text>
                    <div className="text-light" data-onsuccess="onSignIn">You are signed in.</div>
                </Navbar.Text>


            </Container>

        </Navbar>
    )
}