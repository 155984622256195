import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchString } from './filtersSlice';

export default function FilterSearch() {
    const dispatch = useDispatch();

    const searchString = useSelector((state) => state.filters.searchString);
    
    function changeHandler(e) {
        dispatch(setSearchString(e.target.value));
    }

    return (
        <Form>
            <InputGroup className="mb-3">
                <InputGroup.Text>Search Tags</InputGroup.Text>
                <Form.Control 
                    aria-label="Search tags" 
                    value={searchString} 
                    onChange={changeHandler} 
                    type="text" 
                    placeholder="Search Tags" />
            </InputGroup>
        </Form>
    )
}