import { configureStore } from '@reduxjs/toolkit';
import filtersReducer from '../features/filters/filtersSlice';
import thumbnailsReducer from '../features/thumbnailViewer/thumbnailsSlice';
import viewerReducer from '../features/viewer/viewerSlice';

export const store = configureStore({
  reducer: {
    filters: filtersReducer,
    thumbnails: thumbnailsReducer,
    viewer: viewerReducer,
  },

  // Disable serializableCheck middleware. This slows things down in development when we have big objects.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
