import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closePhoto } from './viewerSlice';

export default function Viewer() {

    const dispatch = useDispatch();

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const showing = useSelector( (state) => state.viewer.showing );
    const loadedPhotoId = useSelector( (state) => state.viewer.loadedPhotoId);

    const imgUrl=`https://famphotos.falkor.gen.nz/photo_api/slim/photos/img/${width}/${height}/${loadedPhotoId}/familyphoto.jpg`;

    function handleClose() {
        dispatch(closePhoto());
    }

    return(

        <Modal show={showing} onHide={handleClose} dialogClassName="display-table">
            <Modal.Header closeButton>

            </Modal.Header>

            <Modal.Body>

                <img src={imgUrl} style={{maxHeight:'80vh'}} alt="Being viewed" />

            </Modal.Body>

        </Modal>

                
    )
}