import React from 'react';
import Container from 'react-bootstrap/Container';

export default function SyncTab() {
    return (
        <Container className="border border-top-0 p-3">

            <form className="w3-container w3-padding">
                <p>If you have added more images into a directory you need to sync the directory to add those new files into the database. They will be added into the directory's default collection.</p>


                <div data-bind="foreach: directories">
                    <p>
                    <input className="w3-check" type="checkbox" data-bind="checked: $root.admin.selectedDirectories, value: DirName" />
                    <label data-bind="text:DirName"></label>
                    </p>
                </div>

                <p>
                    <span data-bind="click: function() { $root.admin.selectAll(directories()) }">Select All</span>  / 
                    <span data-bind="click: $root.admin.selectNone">Select None</span>
                </p>

                <div className="w3-button w3-large w3-gray w3-margin-bottom" data-bind="click: syncDirs">Sync Selected</div>

            </form>
        </Container>
    )
}