import React, { useEffect } from 'react';
import SideBar from './SideBar';
import Thumbnails from './Thumbnails';
import { useParams } from 'react-router';
import { loadIdsFromFilters, clearPhotoIds, setCurrentPage, setFilters } from './thumbnailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import ThumbnailPagination from './ThumbnailPagination';
import Viewer from '../viewer/Viewer';

export default function ThumbnailViewer() {
    const params = useParams();     // Get the stuff from the URL

    const dispatch = useDispatch();
    const status = useSelector( (state) => state.thumbnails.status );
    const currentPage = useSelector( (state) => state.thumbnails.currentPage );
    const totalPages = useSelector( (state) => state.thumbnails.totalPages );
    const filters = useSelector( (state) => state.thumbnails.filters );

    // When the URL params change load different photo ids
    useEffect(() => {

        const delim = ':';
        const filters = params.filters.split('/').map( tag => {
            const [group, keyword] = tag.split(delim).map( str => str.replaceAll('+',' '));

            return { group, keyword } 
        });

        dispatch(setFilters({ filterMode: params.filterMode, filters}));

        return () => {
            dispatch(clearPhotoIds());
        }

    }, [params, dispatch]);

    useEffect(() => {
        dispatch(loadIdsFromFilters({ filterMode: filters.filterMode, filters: filters.filters, page: currentPage }))
    }, [currentPage, filters, dispatch]);

    function gotoPageHandler(number) {
        dispatch(setCurrentPage(number))
    }

    let content = '';

    if (status === 'loading') {
        content = "Loading..."
    }
    else if (status === 'ok') {
        content = <Thumbnails />
    }

    return(
        <div >
            <Viewer />

            {content}

            <ThumbnailPagination 
                currentPage={currentPage}
                totalPages={totalPages}
                gotoPageHandler={gotoPageHandler} 
            />
                
        </div>        
    )
}