import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Thumbnail from './Thumbnail';

export default function Thumbnails({ clickHandler }) {

    const photoIds = useSelector( (state) => state.thumbnails.photoIds);

    //const thumbnails = Array(12).fill(0,0);
    // console.log(thumbnails);

    return (

      <Container>
        <Row className="g-3">
          {photoIds.map( photo => (
            <Col  key={photo.photoId} xs={6} sm={6} md={4} lg={3} xxl={2} className="text-center">
              <Thumbnail photoInfo={photo} clickHandler={clickHandler} />
            </Col>
          ))}

        </Row>

      </Container>                  
        
    )
}