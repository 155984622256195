import React from 'react';
import classNames from 'classnames';

export default function FilterButton(props) {

    const { tag, grouped, clickHandler, visible, filterMode, loading } = props;

    let tagText = '';

    if (grouped) {
        if (filterMode==='any') {
            if (tag.active) {
                tagText = `${tag.keyword} (${tag.initialCount})`;

            }
            else {
                tagText = `${tag.keyword} (+${tag.count})`;
            }
        }
        else {
            tagText = `${tag.keyword} (${tag.count})`;
        }
    }
    else {
        if (filterMode==='any') {
            tagText = `${tag.group}:${tag.keyword} (${tag.initialCount})`;
        }
        else {
            tagText = `${tag.group}:${tag.keyword}`;

        }
    }

    return (
        <button 
            onClick={ () => clickHandler(tag) } 
            type="button" 
            style={{ 
                display: visible ? null : 'none', 
                cursor: loading ? 'wait' : null
            }}
            disabled={loading || (!tag.active && tag.count===0)}
            className={classNames({
                btn: true,
                'btn-secondary': tag.active,
                'btn-light': !tag.active,
                'm-1' : true,
                'btn-sm' : true,
                'fw-lighter' : tag.count===0,
                'fw-normal' : tag.count>0
            })}
        >
            {tagText}

    </button>

    )
}